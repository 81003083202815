App.card = {
  init:->
    app = this

    @colorsOwlInit()
    @galleryOwlInit()
    @zoomInit()
    @previewsHandler()

    @galleryImageLazy()
    @galleryBgHandler()
    @favoritesHandler()
    @wideGallery()

    @scrollBlock = false
    @scrollBlockTime = 1100

    @sideImageIsOn = false
    @galleryIsOn = false

    @initialTouchY = undefined

    window.preventEvent = (e)->
      e.preventDefault()
      retun false

    if !App.os?
      App.detectOS()
    if(!App.mob)
      $('.side-image-wrapper .side-image').mCustomScrollbar({
        theme:"dark"
        scrollInertia: 300
        mouseWheel:
          preventDefault: true
        autoHideScrollbar: true
        })

    $('.wp .view span').click(()->
      app.showSideImage(this)
    )

    $('.side-image-wrapper .close').click(()->
      app.hideSideImage()
    )

    if App.mob
      video = $('.gallery .bgs .bg-video-wrapper.active')
      if video.length > 0
        video.find('video')[0].play()

    # Обработчик событий колесика мыши или тача
    galleryHandler = (e)->
      if(App.viewWidth <= 1024)
        return

      if(!app.scrollBlock && !app.sideImageIsOn)
        if(e.deltaY < 0)

          if(!app.galleryIsOn)
            
            app.showGallery()
            app.galleryIsOn = true
            app.scrollBlock = true
            setTimeout(
              ()->
                app.scrollBlock = false
              app.scrollBlockTime)
        else
          if(app.galleryIsOn)
            app.hideGallery()
            app.scrollBlock = true
            app.galleryIsOn = false
            setTimeout(
              ()->
                app.scrollBlock = false
              app.scrollBlockTime)
      return

    # СоБытие колесика мыши передается напрмую в обработчик
    # без изменений
    $('body').on(
      'mousewheel'
      (e)->
        galleryHandler(e)
        return
    )

    # Запоминаем начальную позицию тача
    $('body').on(
      'touchstart'
      (e)->
        app.initialTouchY = e.touches[0].clientY
        return
    )

    # Эмулируем событие скролла при дельте перемещения больше 50
    $('body').on(
      'touchmove'
      (e)->
        if app.initialTouchY? &&
        Math.abs(e.touches[0].clientY - app.initialTouchY) > 50
          ev = {}
          if e.touches[0].clientY < app.initialTouchY
            ev.deltaY = -1
          else
            ev.deltaY = 1
          galleryHandler(ev)
          app.initialTouchY = undefined
        return
    )

    # Не обрабатываем тач на картинках и в галерее
    $('.gallery, .wp .pic').on(
      'touchstart'
      (e)->
        e.stopPropagation()
        return
    )

    return

  colorsOwlInit:->
    @colorsOwl = $('.colors .owl-carousel').owlCarousel(
      items:6
      margin:10
      loop:false
      nav:true
      navText:['','']
      responsive:{
        '0': {
          items:3
        }
        '480':{
          items:4
        }
        '600':{
          items:5
        }
        '768':{
          items:6
        }
        '1025': {
          items: 4
        }
        '1200': {
          items: 5
        }
        '1366':{
          items: 6
        }
      }
    )
    return

  galleryOwlResponsive:
    0:{
      items: 3
    }
    '400': {
      items: 4
    }
    '480': {
      items: 5
    }
    '600': {
      items: 6
    }
    '768': {
      items: 7
    }
    '800': {
      items: 8
    }
    '960': {
      items: 9
    }
    '1025': {
      items: 6
    }

  galleryOwlInit:->
    that = this
    @galleryOwl = $('.gallery .owl-carousel').owlCarousel(
      margin:10
      loop:false
      nav:true
      navText:['','']
      mouseDrag:false
      responsive: that.galleryOwlResponsive
      # responsiveBaseElement: $('.gallery')[0]
    )
    return

  zoomInit:->
    $('.wp .pic').each(()->
      big_url = $(this).attr('data-zoom')
      $(this).zoom({
        url: big_url
        on: 'click'
        onZoomIn: ()->
          $('.zoomed').trigger('click')
          $(this).addClass('zoomed')
          return
        onZoomOut: ()->
          $(this).removeClass('zoomed')
          return
      })
    )
    return

  favoritesHandler:->
    app = this

    currentPageId = $('.card').attr('page-id')
    isInFavorites = _.some(Cookies.getJSON('favorites'), (obj)->
      return obj.groupId == currentPageId
    )
    
    if(isInFavorites)
      $('.to_fav').addClass('active')

    $('.to_fav').on(
      'click'
      ()->
        if($(this).hasClass('active'))
          app.favoritesRemove()
        else
          app.favoritesAdd()
          if window.location.indexOf('/en/')!=-1
            window.location = window.ajaxURL+'/en/konstruktor'
          else
            window.location = window.ajaxURL+'/konstruktor'
        return
    )
    return

  favoritesAdd:->
    favorites = Cookies.getJSON('favorites')
    if(typeof favorites == "undefined")
      favorites = []

    $('.wp').each(
      (e)->
        bgSrc = $(this).find('.pic').attr('data-bg')
        #Убираем url() из css
        # bgSrc = bgSrc.substring(5, bgSrc.length - 2)

        favItem = {
          id: $(this).find('.art span').html()
          groupId: $('.card').attr('page-id')
          smallImgUrl: bgSrc              
          bigImgUrl: $(this).find('.pic').attr('data-zoom')
        }

        favorites.push(favItem)
        return
    )

    favorites = _.uniq(favorites, false, (a)->
      return a.id
    )

    $('.to_fav').addClass('active')

    Cookies.set('favorites', favorites, {expires: 7})

    return

  favoritesRemove:->
    favorites = Cookies.getJSON('favorites')
    if(typeof favorites == "undefined")
      favorites = []

    currentPageId = $('.card').attr('page-id')
    favorites = _.reject(favorites, (obj)->
      return obj.groupId == currentPageId
    )

    $('.to_fav').removeClass('active')

    Cookies.set('favorites', favorites, {expires: 7})
    return

  showGallery:->

    # @itemsToSlideUp = $([]).add('.top .logo').add('.top~.card')
    # @itemsToSlideUp.velocity(
    #   {
    #     transform: 'translateY(-104px)'
    #   }
    #   {
    #     duration: 1000
    #     queue: false

    #   }
    # )

    gallery = $('.gallery')
    gallery.addClass('on')
    $('.scroll_down').addClass('hid')

    video = $('.gallery .bgs .bg-video-wrapper.active')
    if video.length > 0
      video.find('video')[0].play()

    return

  hideGallery:->

    # @itemsToSlideUp = $([]).add('.top .logo').add('.top~.card')
    # @itemsToSlideUp.velocity(
    #   {
    #     transform: 'translateY(0)'
    #   }
    #   {
    #     duration: 1000
    #     queue: false
    #   }
    # )

    gallery = $('.gallery')
    gallery.removeClass('on')

    video = $('.gallery .bgs .bg-video-wrapper.active')
    if video.length > 0
      video.find('video')[0].pause()

    setTimeout(
      ()->
        $('.scroll_down').removeClass('hid')
        return
      1000
    )

    return

  galleryPrev:->
    app = this

    gallery = $('.gallery')
    allBgs = gallery.find('.bgs .bg')
    activeBg = gallery.find('.bgs .bg.active')
    indexOfActiveBg = allBgs.index(activeBg)
    if(indexOfActiveBg==0)
      return false
    else
      gallery.find('.slider .item').eq(indexOfActiveBg - 1).click()
      app.scrollBlock = true
      setTimeout(
        ()->
          app.scrollBlock = false
      500
      )
      return true

  galleryNext:->
    app = this

    gallery = $('.gallery')
    allBgs = gallery.find('.bgs .bg')
    activeBg = gallery.find('.bgs .bg.active')
    lastBg = allBgs.last()
    indexOfLastBg = allBgs.index(lastBg)
    indexOfActiveBg = allBgs.index(activeBg)
    if(indexOfActiveBg!=indexOfLastBg)
      gallery.find('.slider .item').eq(indexOfActiveBg + 1).click()
      app.scrollBlock = true
      setTimeout(
        ()->
          app.scrollBlock = false
      500
      )
      return true
    else
      return false

  showSideImage:(target)->
    $('.logo').css('z-index', '0')
    $(target).parents('.wp').eq(0).find('.side-image-wrapper').addClass('on')
    @sideImagesLazy(target)
    @sideImageIsOn = true
    $('.scroll_down').addClass('hid')
    return

  hideSideImage:->
    $('.side-image-wrapper').removeClass('on')
    @sideImageIsOn = false
    setTimeout(
      ()->
        $('.logo').css('z-index', '1')
        $('.scroll_down').removeClass('hid')
        return
      1000
    )
    return

  sideImagesLazy:(target)->
    img = $(target).parents('.wp').eq(0).find('.side-image img.lazy')
    img.lazy(
      {
        attribute: "data-img-full"
        afterLoad:->
          img.addClass('loaded')
          img.parents('.side-image-wrapper').find('.preloader-lazy').removeClass('active')
      }
    )
    return

  galleryImageLazy:(img)->

    if !img?
      image = $('.gallery .bg').eq(0)
    else
      image = img
    next = image.next()

    if !(image.hasClass('lazy')) && next.length > 0
      @galleryImageLazy(next)
      return

    app = this

    bgSrcUrl = $(image).attr('data-src')
    image.lazy(
      {
        afterLoad: ()->
          image.addClass('loaded')
          if(image.hasClass('active'))
            image.parents('.gallery').find('.preloader-lazy').removeClass('active')

          # bgSrc = image.css('background-image')
          # #Убираем url() из css
          # bgSrcUrl = bgSrc.substring(4, bgSrc.length - 1)
          image.zoom({
            url: bgSrcUrl
            on: 'click'
            callback: ()->
              return
            onZoomIn: ()->
              $(this).add('.gallery').addClass('zoomed')
              return
            onZoomOut: ()->
              $(this).add('.gallery').removeClass('zoomed')
              return
          })

          if(next.length>0)
            app.galleryImageLazy(next)
          return
      }
    )
    return

  galleryBgHandler:()->
    app = this
    $('.gallery .slider .item').on(
      'click'
      ()->
        allItems = $('.gallery .slider .item')
        current = this
        id = 0
        allItems.each(
          (e)->
            if(current==allItems[e])
              id = e
              return
        )
        $('.gallery .bgs .bg').each(()->
          $(this).removeClass('active')
          video = $(this).find('video')
          if video.length > 0
            video[0].pause()
          return
        )
        $('.gallery .slider .item').removeClass('active')

        $(this).addClass('active')

        parent = $(this).parent()
        if(!$(parent).hasClass('active'))
          if($(parent).prev().length != 0 && $(parent).prev().hasClass('active'))
            app.galleryOwl.trigger('next.owl.carousel')
          if($(parent).next().length != 0 && $(parent).next().hasClass('active'))
            app.galleryOwl.trigger('prev.owl.carousel')
        thisSlide = $('.gallery .bgs .bg').eq(id)

        thisSlide.addClass('active')
        video = thisSlide.find('video')
        if video.length > 0
          video[0].play()

        if(!thisSlide.hasClass('loaded'))
          thisSlide.parents('.gallery').find('.preloader-lazy').addClass('active')
        else
          thisSlide.parents('.gallery').find('.preloader-lazy').removeClass('active')
        return
    )
    return

  wideGallery:->
    that = this
    gallery = $('.gallery')
    slider = $('.slider')
    gallery.on(
      'click'
      '.gallery-btn'
      ()->
        gallery.toggleClass('wide')
        if gallery.hasClass('wide')
          that.galleryOwl.data('owl.carousel').options.responsive['1025'] = {items: 12}
          slider.css('width', '100vw')
        else
          that.galleryOwl.data('owl.carousel').options.responsive['1025'] = {items: 6}
          slider.css('width', '')
        that.galleryOwl.trigger('refresh.owl.carousel')
        return
    )
    return

  previewsHandler:->
    items = $('.card .previews .item')
    images = $('.card .full .wp')
    items.on(
      'click'
      ()->
        index = $(this).index()
        images.removeClass('active')
        items.removeClass('active')
        images.eq(index).addClass('active')
        $(this).addClass('active')
        return
    )
    return

}