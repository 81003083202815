App.newsfull = 

  init:->
    app = this
    App.scrollPageContainer(this)
    $( ()->
      app.onloadAnimations()
      app.scrollCallbackHandler()
      return
    )
    return

  scrollCallbackHandler:->
    app = this
    $('.newsblock').each(()->
      if(!App.isBelowViewport(this)||App.mob)
        $(this).addClass('visible')
      return
    )
    return

  onloadAnimations:()->
    $('.top, .footer').removeClass('hid')
    $('.newsfull-page').addClass('loaded')
    return