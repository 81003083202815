App.threejs =

  interiors:
    1: {
      id: 1
      wall1: {positionX: 2, positionY: 0, positionZ: -61, rotationY: 0}
      wall2: {rotationY: -0.53, positionX: 173.5, positionY: 0, positionZ: -90}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    2: {
      id: 2,
      wall1: {positionX: -68, positionY: 50, positionZ: -25, rotationY: 0.75}
      wall2: {positionX: 71, positionY: 50, positionZ: -25, rotationY: -0.84}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    3: {
      id: 3,
      wall1: {positionX: -95, positionY: 30, positionZ: -60, rotationY: 0.47}
      wall2: {positionX: 69, positionY: 30, positionZ: -60, rotationY: -0.7}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    4: {
      id: 4,
      wall1: {positionX: -37.3, positionY: 30, positionZ: -100, rotationY: 0}
      wall2: {positionX: 182, positionY: 11, positionZ: -151, rotationY:0}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    5: {
      id: 5,
      wall1: {positionX: 2, positionY: 30, positionZ: -61, rotationY: 0}
      wall2: {positionX: 0, positionY: 30, positionZ: -61, rotationY: -1.66}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    6: {
      id: 6,
      wall1: {positionX: -16, positionY: 30, positionZ: -61, rotationY: 0.47}
      wall2: {positionX: 69, positionY: 30, positionZ: -61, rotationY: -1.66}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    7: {
      id: 7,
      wall1: {positionX: -90, positionY: 30, positionZ: -35, rotationY: 0}
      wall2: {positionX: 69, positionY: 30, positionZ: -61, rotationY: -1.79}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    8: {
      id: 8,
      wall1: {positionX: -86, positionY: 0, positionZ: 0, rotationY: 0.7}
      wall2: {positionX: 83.5, positionY: 0, positionZ: -20, rotationY: -0.39}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    9: {
      id: 9,
      wall1: {positionX: -67, positionY: 0, positionZ: -35, rotationY: 0.35}
      wall2: {positionX: 90, positionY: 30, positionZ: 0, rotationY: -0.91}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }
    10: {
      id: 10,
      wall1: {positionX: -66, positionY: 0, positionZ: 60, rotationY: 1.5}
      wall2: {positionX: 48, positionY: 0, positionZ: -30, rotationY: 0}
      camera: {positionX: 6, positionY: -3, positionZ: 50}
      interior: {positionX: 6, positionY: -3, positionZ: 0}
    }

  init:()->
    that = this
    @scene = new (THREE.Scene)
    @camera = new THREE.PerspectiveCamera 45, window.innerWidth / window.innerHeight, 0.1, 1000
    @renderer = new THREE.WebGLRenderer({antialias: true})
    @renderer.setPixelRatio(window.devicePixelRatio)
    @renderer.setClearColor 0xcccccc, 1.0
    @renderer.setSize window.innerWidth, window.innerHeight
    #@renderer.antialias = true

    ###@axes = new THREE.AxesHelper 50
    @scene.add @axes###

    #@stats = new @createStats

    ###
    wallGeometry1 = new THREE.PlaneGeometry 200, 200, 1, 1
    wallMaterial1 = new THREE.MeshBasicMaterial({transparent: true, overdraw: true })
    wallMaterial1.map = new THREE.TextureLoader().load()
    wallMaterial1.minFilter = THREE.LinearFilter
    ###
    wallGeometry1 = new THREE.PlaneGeometry 200, 200, 1, 1
    wallMaterial1 = new THREE.MeshBasicMaterial({transparent: true, overdraw: true })
    wallMaterial1.map = new THREE.TextureLoader().load()
    wallMaterial1.minFilter = THREE.LinearFilter

    @wall1 = new THREE.Mesh wallGeometry1, wallMaterial1
    @wall1.name= 'wall1'
    @scene.add @wall1

    wallGeometry2 = new THREE.PlaneGeometry 200, 200, 1, 1
    wallMaterial2 = new THREE.MeshBasicMaterial({transparent: true, overdraw: true })
    wallMaterial2.map = new THREE.TextureLoader().load()
    wallMaterial2.minFilter = THREE.LinearFilter
    #wallMaterial2.color = new THREE.Color('white')
    #wallMaterial2.colorsNeedUpdate = true
    @wall2 = new THREE.Mesh wallGeometry2, wallMaterial2
    @wall2.name= 'wall2'

    @scene.add @wall2

    @camera.position.x = 0
    @camera.position.y = 0
    @camera.position.z = 50
    @camera.lookAt @scene.position

    document.getElementById("three_scene").appendChild @renderer.domElement
    #document.body.appendChild @stats.domElement

    #@addControls(@controls)

    @raycaster = new THREE.Raycaster()
    @mouseVector = new THREE.Vector3()

    $('.threejs').click((e)->
      that.onMouseClick(e)
      return
    )

    ###window.addEventListener 'mouseover', (e)->
      that.onMouseOver(e)
      return###

    @render()
    return true

  setControls:(id)->
    @controls =
      interiorDisplay: true
      wall1RotationY: @interiors[id]["wall1"]["rotationY"]
      wall1PositionX: @interiors[id]["wall1"]["positionX"]
      wall1PositionY: @interiors[id]["wall1"]["positionY"]
      wall1PositionZ: @interiors[id]["wall1"]["positionZ"]
      wall2RotationY: @interiors[id]["wall2"]["rotationY"]
      wall2PositionX: @interiors[id]["wall2"]["positionX"]
      wall2PositionY: @interiors[id]["wall2"]["positionY"]
      wall2PositionZ: @interiors[id]["wall2"]["positionZ"]
      interiorPositionX: @interiors[id]["interior"]["positionX"]
      interiorPositionY: @interiors[id]["interior"]["positionY"]
      interiorPositionZ: @interiors[id]["interior"]["positionZ"]
      cameraPositionX: @interiors[id]["camera"]["positionX"]
      cameraPositionY: @interiors[id]["camera"]["positionY"]
      cameraPositionZ: @interiors[id]["camera"]["positionZ"]
    return

  onMouseClick:(e)->
    @mouseVector.x = (2 * (e.clientX / window.innerWidth) - 1)
    @mouseVector.y = (1 - 2 * (e.clientY / window.innerHeight))
    @raycaster.setFromCamera(@mouseVector.clone(), @camera)
    intersects = @raycaster.intersectObjects([@wall1, @wall2])
    if intersects.length > 0
      @clearMeshMaterial(intersects[0].object.name)
      @setWallpaper(@img, intersects[0].object.name)
    return

  ###onMouseOver:(e)->
    @mouseVector.x = (2 * (e.clientX / window.innerWidth) - 1)
    @mouseVector.y = (1 - 2 * (e.clientY / window.innerHeight))
    @raycaster.setFromCamera(@mouseVector.clone(), @camera)
    intersects = @raycaster.intersectObjects([@wall1, @wall2])
    if intersects.length > 0
      App.threejs.scene.getObjectByName(intersects[0].object.name).material.color.set(0xff0000)
    return

  onMouseOut:(e)->
    @mouseVector.x = (2 * (e.clientX / window.innerWidth) - 1)
    @mouseVector.y = (1 - 2 * (e.clientY / window.innerHeight))
    @raycaster.setFromCamera(@mouseVector.clone(), @camera)
    intersects = @raycaster.intersectObjects([@wall1, @wall2])
    if intersects.length > 0
      App.threejs.scene.getObjectByName(intersects[0].object.name).material.color.set(0xffffff)
    return###

  render:()->
    @renderer.render @scene, @camera
    #Блок для dat.gui#
    if @scene.getObjectByName('interior')
      @scene.getObjectByName('interior').visible = @controls.interiorDisplay
      #@scene.getObjectByName('wall1').rotation.x = @controls.wall1RotationX
      @scene.getObjectByName('wall1').rotation.y = @controls.wall1RotationY
      #@scene.getObjectByName('wall1').rotation.z = @controls.wall1RotationZ
      @scene.getObjectByName('wall1').position.x = @controls.wall1PositionX
      @scene.getObjectByName('wall1').position.y = @controls.wall1PositionY
      @scene.getObjectByName('wall1').position.z = @controls.wall1PositionZ
      #@scene.getObjectByName('wall2').rotation.x = @controls.wall2RotationX
      @scene.getObjectByName('wall2').rotation.y = @controls.wall2RotationY
      #@scene.getObjectByName('wall2').rotation.z = @controls.wall2RotationZ
      @scene.getObjectByName('wall2').position.x = @controls.wall2PositionX
      @scene.getObjectByName('wall2').position.y = @controls.wall2PositionY
      @scene.getObjectByName('wall2').position.z = @controls.wall2PositionZ
      #@scene.getObjectByName('wall2').position.z = @controls.wall2PositionZ
      @camera.position.x = @controls.cameraPositionX
      @camera.position.y = @controls.cameraPositionY
      @camera.position.z = @controls.cameraPositionZ
      @scene.getObjectByName('interior').position.x = @controls.interiorPositionX
      @scene.getObjectByName('interior').position.y = @controls.interiorPositionY
      @scene.getObjectByName('interior').position.z = @controls.interiorPositionZ
    requestAnimationFrame @render.bind(this)
    #@stats.update()
    return

  addControls: ->
    if @gui
      @gui.destroy()

    @gui = new dat.GUI()
    @gui.domElement.style.padding = '100'
    @gui.add @controls, 'interiorDisplay'
    #gui.add controlObject, 'wall1RotationX', -1, 1
    @gui.add @controls, 'wall1RotationY', -2, 2
    #gui.add controlObject, 'wall1RotationZ', -1, 1
    @gui.add @controls, 'wall1PositionX', -300, 300
    @gui.add @controls, 'wall1PositionY', -300, 300
    @gui.add @controls, 'wall1PositionZ', -300, 300
    #gui.add controlObject, 'wall2RotationX', -1, 1
    @gui.add @controls, 'wall2RotationY', -Math.PI, Math.PI
    #gui.add controlObject, 'wall2RotationZ', -1, 1
    @gui.add @controls, 'wall2PositionX', -300, 300
    @gui.add @controls, 'wall2PositionY', -300, 300
    @gui.add @controls, 'wall2PositionZ', -300, 300
    @gui.add @controls, 'cameraPositionX', -100, 100
    @gui.add @controls, 'cameraPositionY', -100, 100
    @gui.add @controls, 'cameraPositionZ', -100, 100
    @gui.add @controls, 'interiorPositionX', -100, 100
    @gui.add @controls, 'interiorPositionY', -100, 100
    @gui.add @controls, 'interiorPositionZ', -100, 100
    return

  #Функция клика по превью интерьера
  intClick:(img, id)->
    @id = id
    if !!!@interior
      interiorGeometry = new THREE.PlaneGeometry 100, 50, 1, 1
      interiorMaterial = new THREE.MeshBasicMaterial({transparent: true, overdraw: true })
      interiorMaterial.map = new THREE.TextureLoader().load(img)
      interiorMaterial.map.minFilter = THREE.LinearFilter
      @interior = new THREE.Mesh interiorGeometry, interiorMaterial
      @interior.name = 'interior'
      @scene.add @interior
      #@setPositions(id)
    else
      @clearMeshMaterial('interior')
      @setMeshMaterial(img, 'interior')

    @setControls(id)
    #@addControls()
    return

  setPositions: (id) ->
    @scene.getObjectByName('wall1').position.x = @interiors[id]["wall1"]["positionX"]
    @scene.getObjectByName('wall1').position.z = @interiors[id]["wall1"]["positionZ"]
    @scene.getObjectByName('wall1').rotation.y = @interiors[id]["wall1"]["rotationY"]

    @scene.getObjectByName('wall2').position.x = @interiors[id]["wall2"]["positionX"]
    @scene.getObjectByName('wall2').position.z = @interiors[id]["wall2"]["positionZ"]
    @scene.getObjectByName('wall2').rotation.y = @interiors[id]["wall2"]["rotationY"]

    @camera.position.x = @interiors[id]["camera"]["positionX"]
    @camera.position.y = @interiors[id]["camera"]["positionY"]
    @camera.position.z = @interiors[id]["camera"]["positionZ"]

    @interior.position.x = @interiors[id]["interior"]["positionX"]
    @interior.position.y = @interiors[id]["interior"]["positionY"]
    @interior.position.z = @interiors[id]["interior"]["positionZ"]
    return

  #Функция клика по превью обоев#
  wpClick:(img)->
    @img = img
    return

  clearMeshMaterial: (nameMesh) ->
    @scene.getObjectByName(nameMesh).material.map = null
    return

  setMeshMaterial:(img, nameMesh)->
    that = this
    texture = new (THREE.TextureLoader)
    texture.load img, ((texture) ->
        texture.minFilter = THREE.LinearFilter
        texture.needsUpdate = true
        App.threejs.scene.getObjectByName(nameMesh).material.map = texture
        #that.setPositions(@id)
        return
      ), undefined, (err) ->
      console.error 'Не могу загрузить изображение' + nameMesh
    return

  setWallpaper:(img, nameMesh)->
    texture = new (THREE.TextureLoader)
    texture.anisotropy = @renderer.capabilities.getMaxAnisotropy()
    texture.load img, ((texture) ->
        texture.minFilter = THREE.LinearFilter
        texture.needsUpdate = true
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping

        loader = new (THREE.TextureLoader)
        loader.load img, ((tex) ->
          wallpaper = tex
          aspect = wallpaper.image.height / wallpaper.image.width
          texture.repeat.set(5, 5 / aspect)
          App.threejs.scene.getObjectByName(nameMesh).material.map = texture
          return
        ), undefined, (err) ->
          console.error 'error upload wallpaper'
          return

        return
      ), undefined, (err) ->
      console.error 'Не могу загрузить изображение' + nameMesh
    return

  createStats:()->
    @stats = new Stats()
    @stats.setMode(0)
    @stats.domElement.style.position = 'absolute'
    @stats.domElement.style.left = '0'
    @stats.domElement.style.top = '0'
    return @stats

  resize:()->
    @camera.aspect = window.innerWidth / window.innerHeight
    @camera.updateProjectionMatrix()
    @renderer.setSize window.innerWidth, window.innerHeight
    return
