App.news = 

  init:->
    App.scrollPageContainer(this)
    @onloadAnimations()
    @scrollCallbackHandler()
    return

  lazyChain:(chain)->

    app = this

    if(chain.length>0)

      $(chain[0]).lazy(
        {
          attribute: "data-src"
          threshold: 1000000
          afterLoad:->
            $('.onload').removeClass('onload')
            $(chain[0]).addClass('loaded')
            $('.top').removeClass('hid')
            app.lazyChain(_.rest(chain))
            return
        }
      )

    return

  scrollCallbackHandler:->
    app = this
    $('.news-preview .img.lazy, .news-preview .text').each(()->
      if(!App.isBelowViewport(this)||App.mob)
        $(this).addClass('visible')
      return
    )
    return

  onloadAnimations:->

    app = this

    app.lazyChain($('.news-page .lazy'))

    return