App.contact ={

  googleApiKey: App.googleApiKey

  init:->
    @delegateEvents()
    App.scrollPageContainer(this)
    @preventScrollWhenOverMap()
    @placeClickHandler()
    return

  resizeMap: ->
    center = @map.getCenter()
    google.maps.event.trigger(@map, "resize") # баг-фикс гугл карт при ресайзе другой страницы
    @map.setCenter(center)
    return

  delegateEvents:->
    that = this
    $('.tabs__head li').click((e)->
      activeTab = $('.tabs__head .active').index()
      clickedTab = $(e.currentTarget).index()
      if activeTab == clickedTab
        return

      if $(e.target).hasClass('stores_tab')
        $('.places .place').first().click()

      if $(e.target).hasClass('office_tab')
        that.switchMapToPoint({lat: 55.8048948, lng: 37.7803238})

      $('.tabs__head li').removeClass('active').eq(clickedTab).addClass('active')
      $('.tabs__body li').removeClass('active').eq(clickedTab).addClass('active')

      return
    )
    return

  initMap: ->
    that = this

    @mapIcon=
      url: "/assets/images/pin.svg"
      scaledSize: new google.maps.Size(48,48)

    @map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: 55.8048948, lng: 37.7803238}
      #disableDefaultUI: true
      zoom: 18
      icon: that.mapIcon
      styles: [

        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#d6d6d6"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "stylers": [
            {
              "color": "#eaeaea"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on",
              "color": "#aaaaaa"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "stylers": [
            {
              "color": "#969ba2"
            },
            {
              "lightness": 3
            }
          ]
        },
        {elementType: 'labels.text.fill', stylers: [{color: '#999999'}]},
      ]
    })


    that.switchMapToPoint({lat: 55.8048948, lng: 37.7803238})

    google.maps.event.addListenerOnce(@map, 'tilesloaded', ()->
      $('.invisible').html('')
      setTimeout(()->
        $('#map').removeClass('loading')
        return
      ,300)
    )
    google.maps.event.addDomListener(window, "resize", ()->
      that.resizeMap()
      return
    )
    $('#gm_script').remove()
    return

  placeClickHandler:->
    app = this

    $('.places').on(
      'click'
      '.place:not(.active)'
      (e)->
        address = $(this).find('.pure-address').html()

        coords = {}
        coords.lat = parseFloat($(this).find('.address').attr('data-coords-lat'))
        coords.lng = parseFloat($(this).find('.address').attr('data-coords-lng'))

        if(!isNaN(coords.lat)&&!isNaN(coords.lng))
          app.switchMapToPoint(coords, app, this)
        else
          app.getCoordsByPlaceName(address, app.switchMapToPoint, this)




        $('.places .place').removeClass('active')
        $(this).addClass('active')
        if App.mob
          if !e.isTrigger
            $([document.documentElement, document.body]).animate({
              scrollTop: $("#map").offset().top
            }, 1000)


        return
    )
    return

  clearPoint:()->
    if @marker?
      @marker.setMap(null)
      delete @marker
    return

  getCoordsByPlaceName:(address, callback, place)->

    app = this

    $.ajax({
      url: 'https://maps.googleapis.com/maps/api/geocode/json'
      data:{
        address: address
        key: app.googleApiKey
      }
      type:'GET'
      success:(data)->
        callback(data.results[0].geometry.location, app, place)
        return
    })
    return

  switchMapToPoint:(location, context, linkedDiv)->
    if context?
      app = context
    else
      app = this

    app.clearPoint()

    if typeof location != "undefined"

      app.marker =  new google.maps.Marker({
        position: location
        map: app.map
        draggable: false
        icon: app.mapIcon
        zIndex: -20
      })
      app.map.setCenter(location)

      if linkedDiv?
        app.marker.linkedDiv = linkedDiv
        linkedDiv.marker = app.marker

    return

  preventScrollWhenOverMap:()->
    app = this
    $('#map').on(
      'mouseenter'
      ()->
        App.bodyScroller.mCustomScrollbar("disable")
        return
    )
    $('#map').on(
      'mouseleave'
      ()->
        App.bodyScroller.mCustomScrollbar("update")
        return
    )
    return

  scrollCallbackHandler:->
    return

}
