App.constructor =

  activeInt:0
  activeWP: 0

  wallpapersItems:[
    {
      id: "1"
      groupId: "456"
      smallImgUrl: "upload/collections/baccarat/1120/1120-00.png"
      bigImgUrl: "upload/wallpapers/1.jpg"
    }
    {
      id: "2"
      groupId: "456"
      smallImgUrl: "upload/collections/baccarat/1120/1120-01.png"
      bigImgUrl: "upload/wallpapers/2.jpg"
    }
    {
      id: "3"
      groupId: "11"
      smallImgUrl: "upload/collections/baccarat/1120/1120-00.png"
      bigImgUrl: "upload/wallpapers/1.jpg"
    }
    {
      id: "4"
      groupId: "21"
      smallImgUrl: "upload/collections/baccarat/1120/1120-01.png"
      bigImgUrl: "upload/wallpapers/2.jpg"
    }
    {
      id: "5"
      groupId: "00"
      smallImgUrl: "upload/collections/baccarat/1120/1120-00.png"
      bigImgUrl: "upload/wallpapers/1.jpg"
    }
    {
      id: "6"
      groupId: "99"
      smallImgUrl: "upload/collections/baccarat/1120/1120-01.png"
      bigImgUrl: "upload/wallpapers/2.jpg"
    }
    {
      id: "7"
      groupId: "88"
      smallImgUrl: "upload/collections/baccarat/1120/1120-00.png"
      bigImgUrl: "upload/wallpapers/1.jpg"
    }
    {
      id: "8"
      groupId: "77"
      smallImgUrl: "upload/collections/baccarat/1120/1120-01.png"
      bigImgUrl: "upload/wallpapers/2.jpg"
    }
  ]


  init:()->
    app = this
    if App.threejs.init()

      @verticalSlider($('.interiors'))

      $('.show-vert-slider').click(()->
        app.verticalSlider.show()
        $(this).fadeOut(300)
      )

      # @horizontalSlider($('.wallpapers .slider'))

      @readFavoritesCookie()
      @generateSliderContent($('.wallpapers'))


      $('.interiors .item').eq(0).trigger('click', ['doNotHide'])

      $('.clear').click(()->
        $('.remove').each(
          (e)->
            app.horizontalSlider.trigger('remove.owl.carousel', e);
            app.horizontalSlider.trigger('refresh.owl.carousel');
        )
        app.wallpapersItems = []
        app.writeFavoritesCookie()
        app.horizontalSlider.emptySliderChecker()
      )

    return

  delegateEvents: ()->
    $('.interiors').on(
      'click'
      '.item:not(.disabled)'
      ()->
        $('.interiors .item').removeClass('active')
        $(this).addClass('active')
        img = $(this).attr('data-img')
        id = $(this).attr('data-id')
        App.threejs.intClick(img,id)
        #App.threejs.intClick()
        return
    )
    $('.wallpapers .item').click(()->

      if($(this).parents('.owl-item').hasClass('active'))
        $('.wallpapers .item').removeClass('active')
        $(this).addClass('active')
        img = $(this).attr('data-img')
        App.threejs.wpClick(img)
    )
    return

  writeFavoritesCookie:()->
    Cookies.set('favorites', JSON.stringify(@wallpapersItems), {expires: 7})
    return

  readFavoritesCookie:()->
    savedCookie = Cookies.get('favorites')
    if(typeof savedCookie != 'undefined')
      @wallpapersItems = JSON.parse(savedCookie)
    else
      @wallpapersItems = []
    return

  generateSliderContent:(insertInto)->

    generatedSlider = $('<div></div>')

    _.each(@wallpapersItems, (elem)->
      item = $('<div class="item"></div>')
      item.attr('data-img', elem.smallImgUrl)
      item.attr('data-id', elem.id)
      item.attr('data-group-id', elem.groupId)
      item.append('<div class="bg"></div>')
      bg = item.children().eq(0)
      bg.css('background-image', 'url(' + elem.smallImgUrl + ')')
      generatedSlider.append(item)
      return
    )

    generatedSlider.addClass('slider owl-carousel')
    # generatedSlider.find('.item').eq(0).addClass('active')
    generatedSlider.find('.item').wrap('<div class="item-wrapper"></div>')
    generatedSlider.find('.item-wrapper').append('<div class="remove"></div>')

    insertInto.append(generatedSlider)

    @horizontalSlider(insertInto.children('.slider'))

    return

  verticalSlider: (slider)->

    @verticalSlider = slider

    app = this

    slider.next = ()->

      firstActiveElem = @data.inner.children(':not(.disabled)').first()
      lastActiveElem = @data.inner.children(':not(.disabled)').last()
      isLastElem = lastActiveElem.next().length == 0
      nextElemIsLast = lastActiveElem.next().next().length == 0

      if(!isLastElem)

        slider.data.controls.prev.removeClass('disabled')

        firstActiveElem.addClass('disabled')
        lastActiveElem.next().removeClass('disabled')

        @data.translateY -= @data.elemHeight
        @data.inner.css('transform', 'translateY(' + @data.translateY + 'px)')

        if(nextElemIsLast)
          slider.data.controls.next.addClass('disabled')


      return

    slider.prev = ()->

      firstActiveElem = @data.inner.children(':not(.disabled)').first()
      lastActiveElem = @data.inner.children(':not(.disabled)').last()
      isFirstElem = firstActiveElem.prev().length == 0
      prevElemIsFirst = firstActiveElem.prev().prev().length == 0

      if(!isFirstElem)

        slider.data.controls.next.removeClass('disabled')

        lastActiveElem.addClass('disabled')
        firstActiveElem.prev().removeClass('disabled')

        @data.translateY += @data.elemHeight
        @data.inner.css('transform', 'translateY(' + @data.translateY + 'px)')

        if(prevElemIsFirst)
          slider.data.controls.prev.addClass('disabled')

      return

    slider.hide = ()->

      @data.controls.next.addClass('hidden')
      @data.controls.prev.addClass('hidden')

      timeoutsCount = 0

      @data.inner.children().each(
        (i)->
          `var elem = this`
          if(!$(elem).hasClass('disabled'))
            timeoutsCount++
            setTimeout(
              ()->
                $(elem).addClass('hidden')
                timeoutsCount--
                if(timeoutsCount == 0)
                  setTimeout(()->
                    app.verticalSlider.addClass('none')
                    $('.show-vert-slider').fadeIn(300)
                  500)
                return
              100*i
            )
          else
            $(elem).addClass('hidden')
      )

      return

    slider.show = ()->

      app.verticalSlider.removeClass('none')

      @data.controls.next.removeClass('hidden')
      @data.controls.prev.removeClass('hidden')

      @data.inner.children().each(
        (i)->
          `var elem = this`
          if(!$(elem).hasClass('disabled'))
            setTimeout(
              ()->
                $(elem).removeClass('hidden')
                return
              100*i
            )
          else
            $(elem).removeClass('hidden')
      )
      return

    slider.hideAfterSelect = ()->
      that = @data
      that.hideOnMouseleave = false
      that.inner.on(
        'click'
        '.item:not(.disabled)'
        (e, hide)->
          if typeof hide == "undefined"
            that.hideOnMouseleave = true
      )
      return

    slider.mouseleaveHandler = ()->
      that = @data
      @.on(
        'mouseleave'
        ()->
          that.pauseMouseleaveHandler = false
          if(that.hideOnMouseleave)
            setTimeout(
              ()->
                if(that.hideOnMouseleave&&!that.pauseMouseleaveHandler)
                  slider.hide()
                  that.hideOnMouseleave = false
            1000
            )
      )
      @.on(
        'mouseenter'
        ()->
          that.pauseMouseleaveHandler = true
      )
      return


    slider.children().wrapAll('<div class = "vert-slider-outer"><div class = "vert-slider-inner"></div></div>')

    controls = $('<div class="vert-slider-controls"><div class="prev disabled hidden"></div><div class="next disabled hidden"></div></div>')

    inner = slider.find('.vert-slider-inner').eq(0)
    outer = slider.find('.vert-slider-outer').eq(0)

    outer.append(controls)

    outerHeight = outer.height()

    allElems = inner.children()
    elem = allElems.eq(0)
    elemMarginBottom = parseInt(elem.css('margin-bottom'))
    elemHeight = elem.height() + elemMarginBottom

    elemsFitted = Math.floor((outerHeight + elemMarginBottom)/elemHeight)

    slider.data =
      scrollBlocked: false
      scrollBlockTime: 200
      elemHeight: elemHeight
      inner: inner
      outer: outer
      outerHeight: elemHeight * elemsFitted
      translateY: 0
      controls:
        next: slider.find('.vert-slider-controls .next')
        prev: slider.find('.vert-slider-controls .prev')

    outer.css('height', slider.data.outerHeight)

    inner.children().each(
      (e)->
        if(e < elemsFitted)
          $(this).removeClass('disabled')
        else
          slider.data.controls.next.removeClass('disabled')

    )

    slider.show()


    slider.on(
      'mousewheel'
      (e)->
        if(!slider.data.scrollBlocked)
          if(e.deltaY < 0)
            slider.next()
          else
            slider.prev()
          slider.data.scrollBlocked = true
          setTimeout(
            ()->
              slider.data.scrollBlocked = false
          slider.data.scrollBlockTime
          )

    )

    slider.on(
      'click'
      '.vert-slider-controls .next:not(.disabled)'
      ()->
        slider.next()
    )

    slider.on(
      'click'
      '.vert-slider-controls .prev:not(.disabled)'
      ()->
        slider.prev()
    )

    slider.hideAfterSelect()
    slider.mouseleaveHandler()

    return


  horizontalSlider: (slider)->

    app = this

    @horizontalSlider = slider

    slider.findLinkedItems = (groupId)->
      elems = this.find('.item[data-group-id="' + groupId + '"]')
      return elems

    slider.highlightLinkedElems = (highlight, elems)->
      if(highlight)
        elems.addClass('highlited')
      else
        elems.removeClass('highlited')
      return

    slider.emptySliderChecker = ()->
      if(slider.find('.owl-item').length==0)
        $('.dummy').fadeIn(300)
        return true
      return false

    slider.owlCarousel(
      #animateOut: 'fadeOut'
      items:6
      margin:0
      stagePadding:0
      smartSpeed:450
      loop: false
      nav: true
      navText: ['','']
      responsive:
        '0':
          items:4
        '1367':
          items:6

      onInitialized: ()->
        app.delegateEvents()
        # $('.wallpapers .item').eq(0).trigger('click')
        $('.wp_box').addClass('loaded')
        if(!slider.emptySliderChecker())
          return
      mouseDrag: false
      # touchDrag: false
    )


    slider.on(
      'mousewheel'
      (e)->
        if(!slider.scrollBlocked)
          if(e.deltaY < 0)
            slider.trigger('next.owl')
          else
            slider.trigger('prev.owl')
          slider.scrollBlocked = true
          setTimeout(
            ()->
              slider.scrollBlocked = false
          200
          )
    )

    slider.on(
      'click'
      '.item-wrapper .remove'
      ()->

        groupId = $(this).siblings('.item').attr('data-group-id')
        toDelete = slider.findLinkedItems(groupId).parents('.owl-item')

        toDelete.each(
          (e)->
            owlItem = this
            id = $(owlItem).find('.item').attr('data-id')
            position = 0
            $(owlItem).parent().children().each(
              (e)->
                if(owlItem==this)
                  position = e
            )

            slider.trigger('remove.owl.carousel', position);
            slider.trigger('refresh.owl.carousel');

            elemInObj =  _.findWhere(app.wallpapersItems, {id:id})
            posInObj = _.indexOf(app.wallpapersItems, elemInObj);
            app.wallpapersItems.splice(posInObj, 1)
            app.writeFavoritesCookie()
            slider.emptySliderChecker()
            return
        )
    )

    slider.on(
      'mouseenter'
      '.remove'
      (e)->
        groupId = $(this).siblings('.item').attr('data-group-id')
        slider.highlightLinkedElems(true, slider.findLinkedItems(groupId))

    )

    slider.on(
      'mouseleave'
      '.remove'
      (e)->
        groupId = $(this).siblings('.item').attr('data-group-id')
        slider.highlightLinkedElems(false, slider.findLinkedItems(groupId))

    )

    return
