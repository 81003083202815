App.stores =

  storesPoints: []
  googleApiKey: App.googleApiKey
  $el: $('.stores-page')

  init:->
    @chooseCityHandler()
    @initScrolls()
    @preventScrollWhenOverMap()
    @placeClickHandler()

    if App.mob
      $('body').on(
        'touchstart'
        # '#map'
        (e)->
          $('.info-wrapper').remove()
          return
      )

    $('.choose-block .city[data="1268"]').click()
    return

  chooseCityHandler:->
    app = this

    $('.where-to-buy .choose').on(
      'click'
      ()->

        $('.choose-block').addClass('active')
        return
    )

    $('.choose-block .city').on(
      'click'
      ()->
        cityName = $(this).html()
        $('.your-city-name').html(cityName)
        $('.where-to-buy').addClass('loading')
        cityID = $(this).attr('data')
        app.getPlaces(cityID)
        $('.choose-block').removeClass('active')
        return
    )

    $('.choose-block').on(
      'click'
      ()->

        $('.choose-block').removeClass('active')
        return
    )
    return

  initScrolls:->
    @bodyScroller = $('.stores-page').mCustomScrollbar({
      theme:"dark"
      scrollInertia: 0
      autoHideScrollbar: true
      })
    @chooseBlockScroller = $('.choose-block').mCustomScrollbar({
      theme:"dark"
      scrollInertia: 0
      autoHideScrollbar: true
      })
    return

  updatePlaces:(places)->
    app = this
    placesDiv = $('.where-to-buy .places')
    placesDiv.find('.place').remove()
    _.each(places, (element, index, list)->
      newPlace = $('<div class="place"></div>')
      newPlace.append(
        $(
          '<div class="address"><span class="pure-address">' +
          element.PURE_ADDRESS +
          '</span><span class="additional-address">' +
          element.ADDITIONAL_ADDRESS + '</span></div>'
        )
      )
      
      if element.COORDINATS?.LAT? && element.COORDINATS?.LNG?
        addressDiv = newPlace.find('.address')
        addressDiv.attr('data-coords-lat', element.COORDINATS.LAT)
        addressDiv.attr('data-coords-lng', element.COORDINATS.LNG)


      if(element.PHONE!='')
        newPlace.append($('<div class="phone">' + element.PHONE + '</div>'))
      # newPlace.append($('<div class="email">' + element.EMAIL + '</div>'))
      placesDiv.append(newPlace)
      return
    )
    $('.where-to-buy').removeClass('loading')
    return

  getPlaces:(cityId)->
    app = this
    $.ajax({
      url: window.ajaxURL+'/ajax.php'
      data:{
        PAGE: 'STORES'
        ID: cityId
      }
      type:'GET'
      success: (data)->
        app.updatePlaces(JSON.parse(data))
        app.addPointsOnMap()
        app.scrollToCity()
        return
    })
    return

  preventScrollWhenOverMap:()->
    app = this
    $('#map').on(
      'mouseenter'
      ()->
        app.bodyScroller.mCustomScrollbar("disable")
        return
    )
    $('#map').on(
      'mouseleave'
      ()->
        app.bodyScroller.mCustomScrollbar("update")
        return
    )
    return

  initMap: ->
    that = this

    @mapIcon=
      url: "/assets/images/pin.svg"
      # anchor: new google.maps.Point(0,0)
      scaledSize: new google.maps.Size(48,48)

    @map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: 55.755826, lng: 37.6173}
      #disableDefaultUI: true
      zoom: 10
      icon: that.mapIcon
      styles: [

        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#d6d6d6"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "stylers": [
            {
              "color": "#eaeaea"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on",
              "color": "#aaaaaa"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "stylers": [
            {
              "color": "#969ba2"
            },
            {
              "lightness": 3
            }
          ]
        },
        {elementType: 'labels.text.fill', stylers: [{color: '#999999'}]},
      ]
    })

    google.maps.event.addListenerOnce(@map, 'tilesloaded', ()->
      $('.invisible').html('')
      setTimeout(()->
        $('#map').removeClass('loading')
        that.addPointsOnMap()
        return
      ,300)
    )
    google.maps.event.addDomListener(window, "resize", ()->
      #that.resizeMap() нет самой функции
      return
    )
    $('#gm_script').remove()

    return

  getCoordsByPlaceName:(address, callback, place)->

    app = this

    $.ajax({
      url: 'https://maps.googleapis.com/maps/api/geocode/json'
      data:{
        address: address
        key: app.googleApiKey
      }
      type:'GET'
      success:(data)->
        callback(data.results[0].geometry.location, app, place)
        return
    })
    return

  createMapPoint:(location, context, linkedDiv)->
    app = context

    if typeof location != "undefined"

      marker =  new google.maps.Marker({
        position: location
        map: app.map
        draggable: false
        icon: app.mapIcon
        zIndex: -20
      })

      marker.linkedDiv = linkedDiv
      linkedDiv.marker = marker

      app.storesPoints.push(marker)

      info = $('<div class="info-wrapper">' + linkedDiv.innerHTML + '</div>')

      mouseover = (e)->
        target = undefined
        Object.keys(e).map((key)->
          if e[key] instanceof Event
            target = e[key].target
          return
        )

        if App.mob
          $('.info-wrapper').remove()
        if !marker.blockInfo && target?
          bcr = target.getBoundingClientRect()
          $('.stores-page').append(info)
          top = bcr.top + bcr.height
          left = bcr.left + bcr.width/2
          info.css({
            top: top
            left: left
          })
          info.addClass('hid')
          setTimeout(
            ()->
              info.removeClass('hid')
              return
            50
          )
        return

      marker.addListener(
        'mouseover'
        (e)->
          mouseover(e)
          return
      )
      marker.addListener(
        'mouseout'
        (e)->
          info.remove()
          return
      )
      marker.addListener(
        'mousedown'
        (e)->
          marker.blockInfo = true
          info.remove()
          return
      )
      marker.addListener(
        'mouseup'
        (e)->
          marker.blockInfo = false
          return
      )
      marker.addListener(
        'touchstart'
        (e)->
          return
      )
      marker.addListener(
        'click'
        (e)->
          $('.places .place.active').removeClass('active')
          pos = parseInt($(linkedDiv).position().top) - 200
          app.$el.mCustomScrollbar("update")
          app.$el.mCustomScrollbar("scrollTo",pos,{
            scrollEasing:"easeOut"
            scrollInertia: 200
          })
          $(linkedDiv).addClass('active')

          if App.mob
            mouseover(e)
          return
      )

    return

  clearLastPoints:()->
    $(@storesPoints).each(()->
      this.setMap(null)
      delete this
      return
    )
    @storesPoints=[]
    return

  addPointsOnMap:()->
    app = this

    @clearLastPoints()
    $('.where-to-buy .place').each(()->
      place = this

      address = $(place).find('.address .pure-address').html()
      coords = {}
      coords.lat = parseFloat($(place).find('.address').attr('data-coords-lat'))
      coords.lng = parseFloat($(place).find('.address').attr('data-coords-lng'))

      if(!isNaN(coords.lat)&&!isNaN(coords.lng))
        app.createMapPoint(coords, app, place)

      else
        app.getCoordsByPlaceName(address, app.createMapPoint, place)

      return
    )
    return

  scrollToCity:->
    app = this

    currentCity = $('.your-city-name').html()

    if($('.where-to-buy .places .place').length==1)

      $('.where-to-buy .places .place').eq(0).trigger('click')

    else
      app.getCoordsByPlaceName(currentCity, (apiAnswer, context)->
        app = context
        coords = apiAnswer
        app.map.setCenter(coords)
        app.map.setZoom(10)
        return
      )

    return

  placeClickHandler:->
    app = this

    $('.where-to-buy').on(
      'click'
      '.place'
      ()->
        that = this
        # Ждем когда прорисуется точка на карте
        # Не более 10 попыток через каждые 100мс
        f = _.before(
          10
          ()->
            if(app.storesPoints.length != 0)

              markerCoords = {}
              markerCoords.lat = that.marker.position.lat()
              markerCoords.lng = that.marker.position.lng()

              app.map.setCenter(markerCoords)
              app.map.setZoom(17)

              $('.places .place.active').removeClass('active')
              $(that).addClass('active')

            else
              setTimeout(
                ()->
                  f()
                  return
                100
              )
            return
        )

        f()

        return
    )
    return

  sendNewAddressCoordsToBackend:(address, coords)->
    return