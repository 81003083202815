window.App =
  loaded: false
  mob: false #mobile device
  prelHid: false
  oldBrowser: false
  scrollInertia: 300

  googleApiKey: 'AIzaSyBUIz5S4cSgFrkrVVaAqn9wEY9I4Wd0jGk'

  loadApp: ->
    App.findWidth()
    App.detectOS()
    App.page = $('body').attr('data-page')
    @globalEvents()
    return

  findWidth: ->
    App.viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth or 0)
    return

  globalEvents: ->
    #@formsInit()
    @mainNav()
    @resizeDetect()
    return

  scrollPageContainer:(caller)->
    that = this

    if(!App.mob)
      scrollTop = $('.scroll_top')
      if scrollTop.length > 0
        scrollTop.on(
          'click'
          ()->
            $(that.bodyScroller).mCustomScrollbar("scrollTo", "top")
            return
        )
      
      $('.page-container').css('overflow', 'hidden')
      $('.page-container').css('height', '100vh')
      @bodyScroller = $('.page-container').mCustomScrollbar({
        theme:"dark"
        scrollInertia: App.scrollInertia
        autoHideScrollbar: true
        callbacks:
          whileScrolling: ()->
            if(caller?)
              caller.scrollCallbackHandler.apply(caller)
            if this.mcs.top < -100
              scrollTop.addClass('visible')
            else
              scrollTop.removeClass('visible')
            return
      })

    return

  formsInit: ->

    $('.send').click ->
      form = $(this).parents('form')
      valid = true
      name = undefined
      phone = undefined
      $(form).find('input[type="text"]').each (el) ->
        if $(this).val() == $(this).attr('data-val')
          $(this).addClass 'attention'
          valid = false
          return false
        return
      name = $('input[name=\'name\']').val()
      phone = $('input[name=\'phone\']').val()
      if valid == true
        $('form').submit()
      return
    $('form').on 'click', 'input[type="text"], textarea', ->
      if $(this).val() == $(this).attr('data-val')
        $(this).val('').addClass 'filled'
      return
    $('form').on 'blur', 'input[type="text"], textarea', ->
      if $(this).val() == ''
        $(this).val($(this).attr('data-val')).removeClass 'filled'
      return
    $('form').on 'click', '.attention', ->
      $(this).removeClass 'attention'
      return
    return

  mainNav: ->
    $('.nav_btn').click(()->
      $('.nav').addClass('active')
      return
    )
    $('.search_btn').click(()->
      $('.search_form').addClass('active')
      return
    )
    $('.search_form').click(()->
      $('.search_form').removeClass('active')
      return
    )
    $('.search_form input, .search_form .submit_btn').click((e)->
      e.stopPropagation()
      return
    )
    $('.search_form .submit_btn').click((e)->
      $('.search_form form').submit()
      return
    )

    $('.close_btn').click(()->
      $('.nav, .search_form').removeClass('active')
      return
    )

    $('.nav_ul li a').hover(()->
      id = $(this).parent().index()
      $('.pix_ul li').removeClass('active')
      $('.pix_ul li').eq(id).addClass('active')
      return
    ,()->)
    return

  detectOS: ->
    if(navigator.appVersion.indexOf("Android")!=-1)
      App.os = "Android"
      App.mob =true
    if(navigator.appVersion.indexOf("iOS")!=-1||navigator.appVersion.indexOf("iPhone")!=-1)
      App.os = "iOS"
      App.mob =true
    return

  checkTurn: ->
    if(($(window).width()< 640 && window.innerHeight < window.innerWidth) || window.innerHeight < 480 )
      $('.turn_device').show()
    else  setTimeout(()->
        $('.turn_device').fadeOut()
        return
      ,500)
    return

  isBelowViewport:(selector)->

    treshold = 200
    if(selector.getBoundingClientRect().y - document.documentElement.clientHeight + treshold > 0)
      return true

    return false

  showPreloader: ->
    $('.preloader').removeClass('tl')
    if App.oldBrowser
      $('.preloader').removeClass('hid')
      return
    return

  hidePreloader: ->
    if App.prelHid
      return
    App.prelHid = true
    if App.oldBrowser
      $('.preloader').addClass('hid')
      return
    $('.preloader').addClass('br')
    setTimeout(()->
      $('.preloader').addClass('notr').addClass('tl').removeClass('br')
      return
    ,1400)
    setTimeout(()->
      $('.preloader').removeClass('notr')
      return
    ,1500)
    return

  lazyNavChain:(chain)->
    if(!chain?)
      chain = $('.nav .lazy')

    if(chain.length>0)

      $(chain[0]).lazy(
        {
          attribute: "data-src"
          afterLoad:->
            App.lazyNavChain(_.rest(chain))
            return
        }
      )
    return

  resizeDetect: ->

    rtime = 0
    timeout = false
    delta = 800
    w_old = 0
    w_new = 0

    resizeend = ->
      App.findWidth()

      if new Date() - rtime < delta
        setTimeout(()->
          resizeend()
          return
        , delta)
      else
        timeout = false
        w_new = Math.max(document.documentElement.clientWidth, window.innerWidth or 0)
      return

    $(window).resize ->
      #App.checkTurn()
      App.findWidth()
      if(timeout == false)
        timeout = true
        rtime = new Date
        w_old = Math.max(document.documentElement.clientWidth, window.innerWidth or 0)
        setTimeout(()->
          resizeend()
          return
        , delta)
      return

    return

#---
#Ready!
#---

$(document).ready ->
  App.loadApp()
  setTimeout(()->
    App.hidePreloader()
  ,1000)
  return

window.onload = ->
  App.loaded = true
  App.hidePreloader()

  if($('.nav').length>0)
    App.lazyNavChain()

  if !App.page?
    App.page = $('body').attr('data-page')
  
  if App[App.page].init? && typeof App[App.page].init == 'function'
    App[App.page].init()

  return
