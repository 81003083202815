App.collections =
  ajaxURL:window.ajaxURL+'/ajax.php'
  init:->
    # console.log @ajaxURL

    @trash()
    $('.filter').mCustomScrollbar({
      theme:"dark"
      scrollInertia: 0
      autoHideScrollbar: true
    })

    App.scrollPageContainer()

    $('body').on('click', '.filter .close_btn', ()->
      $('.filter').removeClass('active')
      return
    )
    $('body').on('click', '.filter_btn', ()->
      $('.filter').addClass('active')
      return
    )
    $(window).on('scroll', ()->
      if ($('.collections-page').offset().top - $(window).scrollTop())<1
        $('.filter_btn').addClass('fx')
      else
        $('.filter_btn').removeClass('fx')
      return
    )
    @checkUrlParams()
    return

  checkUrlParams:->
    url = new URL(window.location.href)
    brand = url.searchParams.get("brand")
    if(brand!=undefined)
      label = $('.filter label[data-value="'+brand+'"]')[0]
      if(label!=undefined)
        label.click()
    return  

  collectParams:->
    that = this

    $('.filter-block').each(
      (e)->
        param = $(this).attr('data-param')
        checked = $(this).find('.active').map(()->
          return $(this).attr('data-value')
        ).get().join()
        that.params[param] = checked
        return
    )

    pageNumber = $('.pagination li.active')
    if(pageNumber.length>0)
      that.params['PAGINATION_NUMBER'] = pageNumber.html()
    else
      that.params['PAGINATION_NUMBER'] = ""

    return

  trash:->
    that = this
    $('.filter label input:checked').each(
      ()->
        $(this).parent().addClass('active')
        return
    )

    @params = {}
    _.each($('.filter-block'), (ft)->
      if !ft?
        return
      param = $(ft).attr('data-param')
      if !param?
        return
      that.params[param]=[]
      return
    )

    $('body').on(
      'click'
      '.filter label input'
      (e)->
        $('.pagination li').removeClass('active')
        $(this).parent().toggleClass('active')
        that.collectParams()
        that.getCollections()
        return
    )

    $('body').on(
      'click'
      '.pagination li'
      (e)->
        $('.pagination li').removeClass('active')
        $(this).addClass('active')
        that.collectParams()
        that.getCollections()
        return
    )

    $('.clear-filter').click(()->
      _.each(that.params, (param, key)->
        that.params[key]=[]
        return
      )
      $('.filter label input:checked').each(()->
        $(this).removeAttr('checked')
        $(this).parent().removeClass('active')
      )
      that.getCollections()
      return
    )

    $('.filter-title').click(()->
      $(this).parent().toggleClass('hidden')
      return
    )
    return

  getCollections:->
    @params['PAGE'] = 'FILTER'
    @params['sort'] = $('.sort-panel .sort').attr('data-sort')
    #console.log @params['sort']
    $('.collections').addClass('loading')
    $.get(
      @ajaxURL
      @params
      (data)->
        $('.collections-wrapper').html(data)
        setTimeout(()->
          $('.collections').removeClass('loading')
          return
        ,300)

        return
    )
    return