App.home = 
  parallaxOn: false
  onloadAnimationsFinished: false
  scrollAllowed: false
  singleMode: false
  animationSettings:
    initial:
      durations:
        maxDuration: 2500
        transform: '3s'
        opacity: '1s'
    onNewSlide:
      durations:
        maxDuration: 2000
        transform: '2s'
        opacity: '1s'
  parallax:
    maxX: 20
    maxY: 20

  animIsOn: false
  mouseleaveAnimFinished: true

  init:->

    app = this
    initialSlides = $('.bg-slide.initial')


    initialSlides.addClass('active')
    # @contentSliderInit()

    @scrollSlides()
    @slideOnClick()

    # if !App.mob
    if true

      theVideo = $('#start-video')[0]

      theVideo.play()

      that = this
      setTimeout(
        ()->
          if $('.start-video-wrapper').length > 0
            if theVideo.paused || theVideo.seekable.length == 0
              app.playVideo()
              app.stopVideo()
          return
        5000
      )

      theVideo.onplaying = ()->
        app.playVideo()
        return

      theVideo.ontimeupdate = (e)->
        if theVideo.currentTime / theVideo.duration > 0.7
          if !theVideo.calledBgLazy
            theVideo.calledBgLazy = true
            app.bgSliderLazy()
        return

      theVideo.onsuspend = (e)->
        if !theVideo.calledBgLazy
          theVideo.calledBgLazy = true
          app.bgSliderLazy()
        return

      theVideo.onended = ()->
        app.stopVideo()
        return

      $('.skip-video-wrapper').on(
        'click'
        ()->
          app.stopVideo()
          return
      )
    else
      @playVideo()
      @stopVideo(true)




    # Debug part
    # $('body').append('<div class="test" style="position: fixed; top: 0; left: 0; color: white;"></div>')
    # testVar = $('.test')
    # setInterval(
    #   ()->
    #     testVar.html(app.scrollAllowed)
    #     return
    #   15
    # )


    return

  stopVideo:(now)->
    if now
      time = 0
    else
      time = 1000
    that = this
    setTimeout(
      ()->
        $('#start-video')[0].pause()
        $('.start-video-wrapper').remove()
        initialSlides = $('.bg-slide.initial')
        that.contentSliderInit()
        that.startBgTransition(initialSlides, true)
        that.bgSliderLazy()
        return
      time
    )
    $('.start-video-wrapper').removeClass('active')
    return

  playVideo:->
    @onloadBaseAnimations()
    return

  contentAnimation:->
    app = this
    $('.content-slider').on(
      'mouseenter'
      '.active .content-slide'
      ()->
        if $('.content-slider .owl-item.active').length > 1

          o = this

          # Дополнительно запрещаем скролл
          # иначе лезут баги при скролле
          app.scrollAllowed = false

          #Запрет запуска анимации при mouseleave, если не было анимации по mouseenter
          o.hoverIsActual = true
          #Запрет запуска анимации при mouseleave, если не было анимации по mouseenter
          o.mouseenterAnimStarted = false

          #Проверка на наличие активных анимаций
          timer = setInterval(
            ()->
              if(!app.animIsOn&&app.mouseleaveAnimFinished)
                #Задержка перед выполнением анимации
                setTimeout(
                  ()->
                    if(o.hoverIsActual)
                    
                      #Animation begin
                      app.animIsOn = true
                      app.mouseleaveAnimFinished = false
                      app.scrollAllowed = false
                      app.bgSlideOn($(o).attr('data-id'))

                      $(o).removeClass('not-hovered')
                      o.mouseenterAnimStarted = true
                      o.owlItem = $(o).parent()
                      o.transformedOwlItems = []
                      o.animWidth = o.owlItem.css('width')

                      activeOwlItems = o.owlItem.parent().find('.active')
                      hoverElemWasReached = false
                      o.lastSlide = $('.content-slider .owl-item.active').last().find('.content-slide')
                      o.firstSlide = $('.content-slider .owl-item.active').first().find('.content-slide')
                      o.isLastElem = o.lastSlide[0] == $(o)[0]

                      $(o).velocity(
                        {
                          width: ['200%', '100%']
                          height: ['100%', '100px']
                        }
                        {
                          queue: false
                          duration: 300
                          easing: 'ease'
                          complete: ()->
                            app.animIsOn = false
                            return
                        }
                      )
                      
                      if(!o.isLastElem)

                        o.lastSlide.velocity(
                          {
                            opacity: 0
                          }
                          {
                            queue: false
                            duration: 300
                          }
                        )

                        activeOwlItems.each(
                          ()->
                            if($(this)[0]==o.owlItem[0])
                              hoverElemWasReached = true
                            else
                              if(hoverElemWasReached)
                                $(this).velocity(
                                  {transform: 'translateX(' + o.animWidth + ')'}
                                  {
                                    queue: false
                                    duration: 300
                                    easing: 'ease'
                                  }
                                )
                                o.transformedOwlItems.push($(this))
                        )

                      else

                        o.firstSlide.velocity(
                          {
                            opacity: 0
                          }
                          {
                            queue: false
                            duration: 300
                          }
                        )

                        activeOwlItems.each(
                          ()->
                            $(this).velocity(
                              {transform: 'translateX(-' + o.animWidth + ')'}
                              {
                                queue: false
                                duration: 300
                                easing: 'ease'
                              }
                            )
                            o.transformedOwlItems.push($(this))
                        )



                      o.owlItem.addClass('hover')
                      $(o).removeClass('initial')

                      #Animation end
                300
                )

                clearInterval(timer)
            50
          )
    )

    $('.content-slider').on(
      'mouseleave'
      '.active .content-slide'
      #Действия по mouseleave
      ()->
        if $('.content-slider .owl-item.active').length > 1

          o = this
          o.hoverIsActual = false

          if o.mouseenterAnimStarted
            #Проверка на наличие активных анимаций
            timer = setInterval(
              ()->
                if(!app.animIsOn)
                    app.animIsOn = true
                  #Задержка перед выполнением анимации
                  setTimeout(
                    ()->
                      #Animation begin
                      app.bgSlideOff($(o).attr('data-id'))

                      $(o).velocity('stop')
                      $(o).velocity(
                        {
                          width: ['100%', '200%']
                          height: ['100px', '100%']

                        }
                        {
                          queue: false
                          duration: 300
                          easing: 'ease'
                          delay: 300
                          complete: ()->
                            app.animIsOn = false
                            app.mouseleaveAnimFinished = true
                            app.scrollAllowed = true
                            return
                        }
                      )

                      if(o.isLastElem)
                        o.firstSlide.velocity(
                          {
                            opacity: 1
                          }
                          {
                            queue: false
                            duration: 300
                            delay: 300
                          }
                        )
                      else
                        o.lastSlide.velocity(
                          {
                            opacity: 1
                          }
                          {
                            queue: false
                            duration: 300
                            delay: 300
                          }
                        )


                      for i in [0...o.transformedOwlItems.length]
                        $(o.transformedOwlItems[i]).velocity(
                          {transform: 'translateX(0)'}
                          {
                            queue: false
                            duration: 300
                            easing: 'ease'
                            delay: 300
                          }
                        )
                      o.owlItem.removeClass('hover')
                      #Animation end
                  300
                  )
                  clearInterval(timer)
            50
            )
    )

    return

  contentSliderInit:()->
    app = this
    @contentOwl = $('.content-slider.owl-carousel').owlCarousel(
      #animateOut: 'fadeOut'
      items:6
      margin:20
      stagePadding:0
      smartSpeed:450
      loop: true
      nav: false
      responsive:{
        0 : {
          items: 1
        }
        500 : {
          items: 2
        }
        800 : {
          items: 3
        }
        1100 : {
          items: 4
        }
        1300 : {
          items: 5
        }
        1550 : {
          items: 6
        }
        1800 : {
          items: 6
        }
        1920 : {
          items: 7
        }
      }
      onInitialized: ()->
        app.bgParallax()
        app.slideIsSingletonChecker()
        return

      onResized: ()->
        app.slideIsSingletonChecker()
        return

      onTranslated: ()->
        app.slideIsSingletonChecker()
        if(app.singleMode)
          app.activeBgOff()
          app.bgSlideOn($('.content-slider .owl-item.active .content-slide').attr('data-id'))
        return
    )
    return

  bgSliderLazy:(i)->

    app = this

    i ?= 0

    imageSmall = $('.bg-slider-small .bg.lazy').eq(i)
    imageBig = $('.bg-slider-big .bg.lazy').eq(i)
    return if imageSmall.length == 0

    imageSmall.lazy(
      {
        afterLoad: ()->
          imageSmall.addClass('loaded')
          imageBig.lazy(
            {
              afterLoad: ()->
                imageBig.addClass('loaded')
                return
            }
          )

          app.bgSliderLazy(i + 1)
          return
      }
    )
    return

  startBgTransition:(selector, initial)->
    app = this
    durations = @animationSettings.onNewSlide.durations

    if(initial)
      durations = @animationSettings.initial.durations

    selector.each(
      ()->
        $(this).parent().parent().addClass('scaled')
    )

    setTimeout(
      ()->
        if(initial)
          app.contentAnimation()
          $('body').removeClass('onload-animations')
          $('.started-animation').removeClass('started-animation')
          app.scrollAllowed = true
      durations.maxDuration
    )

    return

  bgSlideOn:(id)->
    selector = $('.bg-slide:not(.initial)[data-id="' + id + '"]')
    @bgParallaxOff
    @startBgTransition(selector, false)
    selector.addClass('active')
    return

  bgSlideOff:(id)->
    selector = $('.bg-slide:not(.initial)[data-id="' + id + '"]')
    @bgParallaxOff
    @startBgTransition(selector, false)
    selector.removeClass('active')
    return

  bgParallax:->
    app = this

    @smallParallax = new Parallax(
      $('.bg-slider-small .parallax-scene')[0]
      {
        limitX: 70
        limitY: 70
        onReady: ()->
          app.onloadAnimations()
          return
      }
    )
    @bigParallax = new Parallax(
      $('.bg-slider-big .parallax-scene')[0]
      {
        limitX: 70
        limitY: 70
      }
    )
    return

  onloadBaseAnimations:->
    $('.preloader-lazy').removeClass('active')
    $('.footer').removeClass('hid')
    $('.top').removeClass('hid')
    return

  onloadAnimations:->
    @onloadBaseAnimations()
    $('.content-slider .owl-item.active').each(
      (index)->
        elem = this
        setTimeout(
          ()->
            $(elem).find('.content-slide').addClass('started-animation')
            return
          200*index
        )
    )
    return

  scrollSlides:->
    app = this
    app.throttledSwitch = _.throttle(
      (e)->
        if(app.scrollAllowed)
          if(e.deltaY < 0)
            app.contentOwl.trigger('next.owl')
          else
            app.contentOwl.trigger('prev.owl')
        return
      300
      {trailing: false})
      

    $('body').on(
      'mousewheel'
      (e)->
        app.throttledSwitch(e)
        return
    )

    return

  slideOnClick:->
    app = this
    app.aClicked = false
    $('.content-slider .content-slide').on(
      'click'
      ()->
        if((!App.mob)||($('.content-slider .owl-item.active').length==1))
          if(!app.aClicked)
            link = $(this).find('.toCollection')
            app.aClicked = true
            link[0].click()
        return
    )
    return

  activeBgOff:->
    app = this

    activeBg = $('.bg-slider .bg-slide.active:not(.initial)')
    if(activeBg.length!=0)
      app.bgSlideOff(activeBg.attr('data-id'))
    return

  slideIsSingletonChecker:->
    app = this

    owlItems = $('.content-slider .owl-item.active')
    if(owlItems.length==1)

      if(!app.singleMode)
        app.singleMode = true

        app.activeBgOff()

        app.bgSlideOn($(owlItems).find('.content-slide').attr('data-id'))

      return true
    else
      if(app.singleMode)
        app.singleMode = false
        app.activeBgOff()

    return false


