App.company = 

  contentCarousels: []
  scrollBlocked: false
  scrollOnlyContentBlocks: false
  scrollBlockTime: 1000

  videoChangeTimeout:(f)->
    setTimeout(()->
      f
      return
    1000
    )
    return

  init:->
    that=this
    @initContentSliders()
    @initScroll()
    @scrollToClickedMenuItem()
    @footerToggle()

    $(window).resize ->
      that.setBlockHeight()
      return
    return

  initScroll:->
    that=this

    window.addEventListener('mousewheel', (e)->
      that.mousewheel(e)
    ,false)

    #for FF
    document.addEventListener('DOMMouseScroll', (e)->
      that.mousewheel(e)
    ,false)

    window.addEventListener('touchmove', (e)->
      #e.preventDefault()
      that.touchmove(e)
    ,false)

    window.addEventListener('touchstart', (e)->
      that.lastY = e.touches[0].clientY
    ,false)

    return

  mousewheel:(e)->
    app = this
    if(app.scrollBlocked)
      return

    if(e.deltaY > 0)
      app.scrollForward()
    else
      app.scrollBackward()
    app.scrollBlocked = true
    setTimeout(
      ()->
        app.scrollBlocked = false
      app.scrollBlockTime
    )
    return

  touchmove: (e) ->
    app = this
    e.preventDefault()
    if(!@lastY?)
      @lastY = 0
    if(app.scrollBlocked)
      return
    currentY = e.touches[0].clientY
    if(currentY < (@lastY-50))
      app.scrollForward()
      @lastY = currentY
      app.scrollBlocked = true
      setTimeout(
        ()->
          app.scrollBlocked = false
        app.scrollBlockTime
      )
    else
      if(currentY > @lastY+50)
        app.scrollBackward()
        @lastY = currentY
        app.scrollBlocked = true
        setTimeout(
          ()->
            app.scrollBlocked = false
          app.scrollBlockTime
        )
    return


###
mousewheel:(e)->
  app = this
  if(!app.scrollBlocked)
    if(e.deltaY > 0)
      app.scrollForward()
      app.scrollBlocked = true
      setTimeout(
        ()->
          app.scrollBlocked = false
        app.scrollBlockTime
      )
    else
      app.scrollBackward()
      app.scrollBlocked = true
      setTimeout(
        ()->
          app.scrollBlocked = false
        app.scrollBlockTime
      )
  return
###

  scrollForward:->

    if(!@scrollBlocked)

      if(@isCurrentContentSlideLast()||App.mob||@scrollOnlyContentBlocks)

        currentMenuElem = $('.nav-menu li.active')
        nextMenuElem = currentMenuElem.next()

        currentContentBlockElem = $('.content-block.active')
        nextContentBlockElem = currentContentBlockElem.next()

        currentBgVideoElem = $('.bg-videos .video.active')
        nextBgVideoElem = currentBgVideoElem.next()

        if(nextMenuElem.length!=0)

          currentMenuElem.removeClass('active')

          currentContentBlockElem.removeClass('active')
          currentContentBlockElem.addClass('tr-top')

          currentBgVideoElem.removeClass('active')
          currentBgVideoElem.addClass('tr-top')
          @videoChangeTimeout(currentBgVideoElem.find('video')[0].pause())
          

          nextMenuElem.addClass('active')

          nextContentBlockElem.addClass('active')
          nextContentBlockElem.removeClass('tr-bottom')

          nextBgVideoElem.addClass('active')
          nextBgVideoElem.removeClass('tr-bottom')
          @videoChangeTimeout(nextBgVideoElem.find('video')[0].play())
          
        $('body').trigger('activeMenuItemChanged')


      else
        @nextContentSlide()

    return

  scrollBackward:->
    if(!@scrollBlocked)

      if(@isCurrentContentSlideFirst()||App.mob||@scrollOnlyContentBlocks)

        currentMenuElem = $('.nav-menu li.active')
        prevMenuElem = currentMenuElem.prev()

        currentContentBlockElem = $('.content-block.active')
        prevContentBlockElem = currentContentBlockElem.prev()

        currentBgVideoElem = $('.bg-videos .video.active')
        prevBgVideoElem = currentBgVideoElem.prev()

        if(prevMenuElem.length!=0)

          currentMenuElem.removeClass('active')

          currentContentBlockElem.removeClass('active')
          currentContentBlockElem.addClass('tr-bottom')

          currentBgVideoElem.removeClass('active')
          currentBgVideoElem.addClass('tr-bottom')
          @videoChangeTimeout(currentBgVideoElem.find('video')[0].pause())

          prevMenuElem.addClass('active')

          prevContentBlockElem.addClass('active')
          prevContentBlockElem.removeClass('tr-top')

          prevBgVideoElem.addClass('active')
          prevBgVideoElem.removeClass('tr-top')
          @videoChangeTimeout(prevBgVideoElem.find('video')[0].play())

        $('body').trigger('activeMenuItemChanged')

      else
        @prevContentSlide()

    return

  scrollToClickedMenuItem:->
    app = this
    $('body').on(
      'click'
      '.nav-menu li:not(.active) span'
      ()->

        allMenuItems = $('.nav-menu li')
        currentActiveItem = $('.nav-menu li.active')
        currentClickedItem = $(this).parent()
        currentActiveItemPosition = allMenuItems.index(currentActiveItem)
        currentClickedItemPosition = allMenuItems.index(currentClickedItem)

        app.scrollOnlyContentBlocks = true

        if(currentClickedItemPosition>currentActiveItemPosition)
          action = app.scrollForward
        else
          action = app.scrollBackward

        while(currentActiveItemPosition!=currentClickedItemPosition) 
          action.call(app)
          currentActiveItem = $('.nav-menu li.active')
          currentActiveItemPosition = allMenuItems.index(currentActiveItem)

        app.scrollOnlyContentBlocks = false


        return
    )
    return

  nextContentSlide:->
    $('.content-block.active .content-slider').trigger('next.owl.carousel')
    return

  prevContentSlide:->
    $('.content-block.active .content-slider').trigger('prev.owl.carousel')
    return

  isCurrentContentSlideLast:->
    currentSlide = $('.content-block.active')

    if(currentSlide.find('.owl-next').eq(0).hasClass('disabled'))
      return true

    return false

  isCurrentContentSlideFirst:->
    currentSlide = $('.content-block.active')
    
    if(currentSlide.find('.owl-prev').eq(0).hasClass('disabled'))
      return true

    return false

  initContentSliders:->
    app = this
    $('.content-slider:not(.no-slider)').each(
      ()->
        carousel = $(this).owlCarousel(
          items:1
          margin:20
          stagePadding:0
          smartSpeed:450
          loop: false
          nav: true
          navText: ''
          mouseDrag: false
          touchDrag: App.mob
        )
        app.contentCarousels.push(this)
    )
    $('.content-block:not(.active)').addClass('bottom')

    @setBlockHeight()
    $('.company-page').removeClass 'hid'

    return

  footerToggle:->
    $('body').bind('activeMenuItemChanged', ()->
      if($('.nav-menu li.active').next().length==0)
        $('.scroll_down').hide()
        $('.footer').removeClass('hid')
      else
        $('.footer').addClass('hid')
        $('.scroll_down').show()
      return
    )
    return

  setBlockHeight:->
    maxHeight = Math.max.apply(null, $('.content-block').map(->
      return $(this).height()
    ).get())
    $('.content').css('height', maxHeight)
    return

  trash:->