App.collection = 

  bgTransformData:
    maxScale: 1.5
    currentScale: 1
    currentTranslate: 0
    lastPageOffset: 0

  init:->
    @onloadAnimations()
    # @scrollAnimations()
    App.scrollPageContainer()
    return

  onloadAnimations:->
    $('.bg-wrapper').ready(()->
      $('.onload').removeClass('onload')
      setTimeout(
        ()->
          $('.top, .footer').removeClass('hid')
          return
      500)
      return
    )
    return

  scrollAnimations:->
    app = this

    $(window).on('scroll', (e)->
      scalePtsOnPx = ((app.bgTransformData.maxScale - 1)/$('.bg-main').height())/5

      deltaScroll = window.pageYOffset - app.bgTransformData.lastPageOffset
      app.bgTransformData.lastPageOffset = window.pageYOffset

      scaleChange = Math.abs(deltaScroll) * scalePtsOnPx
      translateChange = Math.abs(deltaScroll)/15

      if(deltaScroll<0)
        # scrollup
        app.bgTransformData.currentScale -= scaleChange
        app.bgTransformData.currentTranslate -= translateChange

        if(app.bgTransformData.currentScale<1)
          app.bgTransformData.currentScale = 1

        if(app.bgTransformData.currentTranslate<0)
          app.bgTransformData.currentTranslate = 0

      else
        # scrolldown
        app.bgTransformData.currentScale += scaleChange
        app.bgTransformData.currentTranslate += translateChange

      # ty = window.pageYOffset/3
      # # sc = $('.bg-main .bg-wrapper').height()/$('.bg-main .bg-wrapper')[0].getBoundingClientRect().y
      # sc = $('.bg-main .bg-wrapper')[0].getBoundingClientRect().y/$('.bg-main .bg-wrapper').height()
      # sc = Math.abs(sc)
      # sc = 1 + sc

      if(app.bgTransformData.currentScale>app.bgTransformData.maxScale)
        sc = app.bgTransformData.maxScale
      else
        sc = app.bgTransformData.currentScale

      ty = app.bgTransformData.currentTranslate

      $('.bg-main .bg-wrapper').css('transform', 'translateY(' + ty + 'px) scale(' + sc + ')')
      # $('.bg-main .bg-wrapper').css('transition', 'none')
      return
    )
    return
